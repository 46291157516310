import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h6>{`POLIS KNOWLEDGE BASE`}</h6>
    <h1>{`Gaming Polis`}</h1>
    <ul>
      <li parentName="ul">{`There are multiple ways an individual or organization could game `}<a parentName="li" {...{
          "href": "/Polis",
          "title": "Polis"
        }}>{`Polis`}</a>{`, and many of them would be trivial to detect in the underlying data.`}</li>
      <li parentName="ul">{`Elaborating at length here serves to accelerate the arms race if and when it begins.`}</li>
      <li parentName="ul">{`But one thing to note: `}<a parentName="li" {...{
          "href": "/extremism",
          "title": "extremism"
        }}>{`extremism`}</a>{` isn't gaming the system. Lots of people showing up from one opinion camp is `}<strong parentName="li">{`extremely`}</strong>{` valuable.`}
        <ul parentName="li">
          <li parentName="ul">{`You may know less about their thoughts and feelings than you think.`}</li>
          <li parentName="ul">{`You might know less about how their opinions index against other populations than you think.`}</li>
          <li parentName="ul">{`They may never have had a chance to interact with other people's opinions.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`For most organizations, gaming is not a concern. If your organization is large, and this is a primary concern, `}<a parentName="li" {...{
          "href": "/contact"
        }}>{`reach out to us`}</a>{` at CompDem. We're happy to discuss the issue in-depth in the course of your engagement or onboarding, and discuss various mitigation strategies for botnets and targeted attacks, from authorization strategies to cloud systems to steps in the subsequent analysis.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      